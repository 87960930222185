import { Helmet } from "react-helmet";
import React, { useMemo, useEffect, useState } from "react";
import View360, { EquirectProjection } from "@egjs/react-view360";
import "../Styles/media.css";
import icon from "../Photos/360-degrees.png";
import { useParams } from "react-router-dom";
import "../Styles/style.css";
import p18 from "../Photos/24.webp";
import p19 from "../Photos/25.webp";
import p20 from "../Photos/27.webp";
import p21 from "../Photos/28.webp";
import p22 from "../Photos/29.webp";
import { Link } from "react-router-dom";

function Room() {
  var { id } = useParams();
  const projection = useMemo(
    () =>
      new EquirectProjection({
        src: "public/IMG_20240706_190144_152.jpg",
        video: false,
      }),
    []
  );
  function handleError(error) {
    // Log the error object as a string for better visibility
    console.error(JSON.stringify(error, Object.getOwnPropertyNames(error)));
  }
  return (
    <>
      <Helmet>
        <title>Apartma {id} v centru pirana</title>
        <meta
          name="description"
          content={`Apartma ${id} v centru Pirana. Apartmaji se nahajajo 40m od tartinijevega trga`}
        />
      </Helmet>
      <div className="room-page">
        <div className="name-rate">
          <div className="name">
            <img src={""} alt="" />
            <h1>Apartma {id}</h1>
            <h3>Kajuhova ulica 2, 6330 Piran</h3>
          </div>
          <div className="book">
            <button>Naj se dopust začne!</button>
          </div>
        </div>

        <div className="imagages-location">
          <div className="images-desc">
            <div className="images">
              <div id="viewer" className="">
              <View360
                  tag="div"
                  autoplay={{ speed: 0.2, pauseOnHover: true }}
                  fov={140}
                  projection={projection}
                />
                <img className="icon-360" src={icon} alt="" srcset="" />
              </div>
              <img src={p18} alt="" />
              <img src={p19} alt="" />
              <img src={p20} alt="" />
              <img src={p21} alt="" />
              <img src={p22} alt="" />
            </div>
            <div className="description">
              <div className="perks"></div>
              <p>
                V srcu Pirana, s pogledom na živahni Tartinijev trg, stoji
                prenovljena hiša z apartmaji, ki združuje zgodovinski čar in
                sodobno udobje. S sedmimi različnimi apartmaji je vsak prostor
                premišljeno zasnovan tako, da ustreza potrebam sodobnih
                popotnikov, hkrati pa ohranja edinstvene arhitekturne
                značilnosti, ki odražajo bogato zgodovino Pirana.
              </p>
              <br />
              <p>
                <span>Zajtrk:</span> Doživite udobje in gostoljubnost v naših
                Akvatour Piran apartmajih. Poleg namestitve lahko uživate tudi v
                čarobnosti jutranjega obroka z našim samopostrežnim zajtrkom. Za
                dodatnih 15 EUR na osebo na dan se lahko vsak dan od 7:00 do
                10:00 sprehodite do zajtrkovanice v hotelu na Tartinijevem trgu,
                le nekaj korakov stran od namestitve, in uživate v bogati izbiri
                jutranjih dobrot.
              </p>
            </div>
          </div>
          <div className="maps">
            <div className="booking-rating">
              <div className="rating">
                <div className="text-">
                  <h2>Zelo dobro</h2>
                  <p>211 komentarijev</p>
                </div>
                <span>8,2</span>
              </div>
              <div className="comment">
                <p>
                  “Terasa je bila krasna. Tudi notranjost lepa in prijetna.
                  Apartma je bil čist. Tako bivalni prostor, kot tudi posoda,
                  brisače, posteljnina.. v...”
                </p>
                <div className="who">
                  <span>S</span>
                  <h4>Sandra</h4>
                  Slovenija
                </div>
              </div>
              <div className="best-loc">
                <h2>Izjemna lokacija!</h2>
                <span>9,6</span>
              </div>

              <Link
                to={
                  "https://www.booking.com/hotel/si/akvatour-apartments.sl.html?aid=356980&label=gog235jc-1FCAsoywFCE2FrdmF0b3VyLWFwYXJ0bWVudHNIIVgDaMsBiAEBmAEhuAEXyAEM2AEB6AEB-AECiAIBqAIDuAK9pqm0BsACAdICJDJiZDQwNDg1LWQ0NDAtNGIyMS1iZDk0LTU5MWQwZjdhZmQyONgCBeACAQ&sid=49aca91956adc2b412c9a986eb400976&dest_id=-91911&dest_type=city&dist=0&group_adults=4&group_children=0&hapos=1&hpos=1&no_rooms=2&req_adults=4&req_children=0&room1=A%2CA&room2=A%2CA&sb_price_type=total&sr_order=popularity&srepoch=1720341314&srpvid=e3da3c5fabab0164&type=total&ucfs=1&lang=sl&soz=1&lang_changed=1#hotelTmpl"
                }
              >
                Poglejte na Booking.com
              </Link>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.802057000262!2d13.570902315825047!3d45.52731807910123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477b6f0c6b7f2b4f%3A0x4b2a8a1e6b7b7b4a!2sKajuhova%20ulica%202%2C%206330%20Piran!5e0!3m2!1ssl!2ssi!4v1627456220871!5m2!1ssl!2ssi"
              title="map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Room;
