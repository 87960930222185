import React, { useEffect, Suspense } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useState } from "react";
import "../Styles/media.css";
import Room from "./Room";
import LoadingIcon from "../Photos/loading.png";
import GiftGenerator from "./GiftGenerator";
const RequireAuth = React.lazy(() => import("./RequireAuth"));
const LoginAdmin = React.lazy(() => import("./Login"));
const Reservation = React.lazy(() => import("./Rezervacija"));
const NavBar = React.lazy(() => import("../content/NavBar"));
const Header = React.lazy(() => import("../content/Main"));
const Lokacija = React.lazy(() => import("../content/Lokacija"));
const Apartments = React.lazy(() => import("../content/Apartments"));
const InfoCards = React.lazy(() => import("./InfoCards"));
const Pogoji = React.lazy(() => import("./Pogoji"));
const Gallery = React.lazy(() => import("./Galerija"));
const Footer = React.lazy(() => import("./Footer"));
const Cookies = React.lazy(() => import("./Cookies"));
const NotFound = React.lazy(() => import("./E404"));
const Business = React.lazy(() => import("./Business"));
const Admin = React.lazy(() => import("./Admin"));
const DashBoard = React.lazy(() => import("./Dashboard"));
const Users = React.lazy(() => import("./Users"));
const Rooms = React.lazy(() => import("./RoomsAdmin"));
const Blogs = React.lazy(() => import("./BlogsAdmin"));
const Settings = React.lazy(() => import("./Settings"));
const Test360 = React.lazy(() => import("./Test360"));

function RedirectExternal({ to }) {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Cookies />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <Suspense
                  fallback={
                    <>
                      <div className="loading">
                        <img src={LoadingIcon} alt="" />
                      </div>
                    </>
                  }
                >
                  <Header />
                </Suspense>
                <InfoCards />
                <Lokacija />
                <Apartments />
                <Gallery />
                <Footer />
              </Suspense>
            </>
          }
        />
        {/* <Route
          path="/business"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <Suspense
                  fallback={
                    <>
                      <div className="loading">
                        <img src={LoadingIcon} alt="" />
                      </div>
                    </>
                  }
                >
                  <Business />
                </Suspense>
                <Footer />
              </Suspense>
            </>
          }
        /> */}
        <Route
          path="/business"
          element={<RedirectExternal to="https://akva-design.com" />}
        />
        <Route
          path="/reservation"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <Reservation />
                <Footer />
              </Suspense>
            </>
          }
        />
        <Route
          path="/terms"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <Pogoji />
                <Footer />
              </Suspense>
            </>
          }
        />
        <Route
          path="/360-test"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <Test360 />
                <Footer />
              </Suspense>
            </>
          }
        />
        <Route
          path="/gift-generator"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <GiftGenerator />
                <Footer />
              </Suspense>
            </>
          }
        />
        <Route
          path="rooms-test/:id"
          element={
            <>
              <Suspense
                fallback={
                  <>
                    <div className="loading">
                      <img src={LoadingIcon} alt="" />
                    </div>
                  </>
                }
              >
                <NavBar />
                <Room />
                <Footer />
              </Suspense>
            </>
          }
        ></Route>
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <Admin />
            </RequireAuth>
          }
        >
          <Route
            path="users"
            element={
              <>
                <Users />
                <Footer />
              </>
            }
          />
          <Route
            path="rooms"
            element={
              <>
                <Rooms />
                <Footer />
              </>
            }
          />
          <Route
            path="blogs"
            element={
              <>
                <Blogs />
                <Footer />
              </>
            }
          />
          <Route
            path="settings"
            element={
              <>
                <Settings />
                <Footer />
              </>
            }
          />
          <Route
            path="dashboard"
            element={
              <>
                <DashBoard />
                <Footer />
              </>
            }
          />
          <Route
            path=""
            element={
              <>
                <DashBoard />
                <Footer />
              </>
            }
          />
        </Route>
        <Route path="/admin/login" element={<LoginAdmin />} />
        <Route
          path="/*"
          element={
            <>
              <NavBar />
              <NotFound />
              <Footer />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
