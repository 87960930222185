import React, { useState } from "react";
import "./gift.css";

function GiftGenerator() {
  const [option, setOption] = useState("popust");
  function Template() {
    return (
      <>
        <div className="gift" style={{width:'1200px', height:'800px', background:'url("https://akvatour.eu/static/media/piran.9082e5ea2743d9bae0a4.webp" center/cover)'}}>

        </div>
      </>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const zacetek = document.getElementById("zacetek").value;
    const konec = document.getElementById("konec").value;
    const zajtrk = document.getElementById("zajtrk").value;
    const dod_opcija_1 = document.getElementById("dod-opcija-1").value;
    const dod_opcija_2 = document.getElementById("dod-opcija-2").value;
    const gifter = document.getElementById("gifter").value;
    console.log(
      name,
      zacetek,
      konec,
      zajtrk,
      dod_opcija_1,
      dod_opcija_2,
      gifter
    );
  };

  return (
    <section className="generator">
      <div className="config">
        <form id="config" onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Komu?" id="name" />
          {/* <select onChange={''}>
                <option value="popust">1</option>
                <option value="darilo">2</option>
                <option value="bon">3</option>
            </select> */}
          <label htmlFor="zacetek">Od:</label>
          <input type="date" name="zacetek" id="zacetek" />
          <label htmlFor="konec">Do:</label>
          <input type="date" name="konec" id="konec" />
          <label htmlFor="zajtrk">Zajtrk:</label>
          <select name="zajtrk" id="zajtrk">
            <option value="da">Da</option>
            <option value="ne">Ne</option>
          </select>
          <input
            type="text"
            name="dodatna-opcija-1"
            placeholder="Dodatna opcija 1"
            id="dod-opcija-1"
          />
          <input
            type="text"
            name="dodatna-opcija-2"
            placeholder="Dodatna opcija 2"
            id="dod-opcija-2"
          />
          <input
            type="text"
            name="gifter"
            placeholder="Kdo podarja?"
            id="gifter"
          />
          <input type="submit" value="Generiraj" />
        </form>
      </div>
      <div className="display">
        <Template />
      </div>
    </section>
  );
}

export default GiftGenerator;
